<template>
  <div>
    <Breadcrumbs main="Acenteler" title="Liste" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <b-form @submit="onFiltreSubmit" inline>
                <label class="sr-only" for="filtre_tipi">Tipi</label>
                <b-form-select id="filtre_tipi" v-model="filtre.Tipi" :options="filtre_tipler"
                  class="mb-2 mr-sm-2 mb-sm-0"></b-form-select>

                <label class="sr-only" for="filtre_kelime">Kelime</label>
                <b-form-input type="text" id="filtre_kelime" v-model="filtre.Kelime" placeholder="Kelime"
                  class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>

                <label class="sr-only" for="filtre_baslangictarihi">Başlangıç Tarihi</label>
                <datepicker :language="tr" input-class="datepicker-here form-control digits mb-2 mr-sm-2 mb-sm-0"
                  id="filtre_baslangictarihi" v-model="filtre.BaslangicTarihi" :clear-button="true"
                  clear-button-icon="fa fa-times" placeholder="Başlangıç Tarihi" :format="date_format"></datepicker>

                <label class="sr-only" for="filtre_bitistarihi">Bitiş Tarihi</label>
                <datepicker :language="tr" input-class="datepicker-here form-control digits mb-2 mr-sm-2 mb-sm-0"
                  id="filtre_bitistarihi" v-model="filtre.BitisTarihi" :clear-button="true"
                  clear-button-icon="fa fa-times" placeholder="Bitiş Tarihi" :format="date_format"></datepicker>

                <label class="sr-only" for="filtre_sehirid">Şehir</label>
                <b-form-select id="filtre_sehirid" v-model="filtre.SehirId" :options="filtre_sehirler"
                  class="mb-2 mr-sm-2 mb-sm-0"></b-form-select>

                <label class="sr-only" for="filtre_minborc">Minumum Borç</label>
                <b-form-input type="text" id="filtre_minborc" v-model="filtre.MinBorc" placeholder="Minumum Borç"
                  class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>

                <label class="sr-only" for="filtre_maxborc">Maksimum Borç</label>
                <b-form-input type="text" id="filtre_maxborc" v-model="filtre.MaxBorc" placeholder="Maksimum Borç"
                  class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>

                <label class="sr-only" for="filtre_durum">Durum</label>
                <b-form-select id="filtre_durum" v-model="filtre.Durum" :options="filtre_durumlar"
                  class="mb-2 mr-sm-2 mb-sm-0"></b-form-select>

                <b-button type="submit" class="pull-right" variant="warning">Listele</b-button>
              </b-form>
              <hr />
              <h5>
                Toplam Alacak :
                <b-badge variant="info"><c-money-cell :fiyat="toplamBorc" simge="₺"></c-money-cell></b-badge>
              </h5>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Islem)="row">
                  <b-btn-group>
                    <a :href="`/acente/detay/${row.item.Id}`" class="btn btn-info btn-xs" target="_blank">Detay</a>
                    <router-link :to="`/acente/kayit/${row.item.Id}`"
                      class="btn btn-success btn-xs">Düzelt</router-link>
                  </b-btn-group>
                </template>
                <template #cell(ToplamBorc)="row">
                  <router-link :to="'/acente/folyo?id=' + row.item.Id">
                    <c-money-cell :fiyat="row.item.ToplamBorc" simge="TL"></c-money-cell>
                  </router-link>
                </template>
                <template #cell(GSM)="data">
                  <a :href="'tel:0090' + data.value">{{ data.value }}</a>
                </template>
                <template #cell(Email)="data">
                  <a :href="'mailto:' + data.value">{{ data.value }}</a>
                </template>
                <template #cell(SonRezervasyonTarihi)="row">
                  <c-tarih-cell :kayitTarihi="row.item.SonRezervasyonTarihi" :rowKey="row.item.Id"></c-tarih-cell>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell :kayitTarihi="row.item.KayitTarihi" :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";

export default {
  data() {
    return {
      breadLinks: [{ path: "/acente/kayit", title: "Ekle", icon: "plus" }],
      tablefields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "TipiStr", label: "Tipi", sortable: true },
        { key: "Adi", label: "Adı", sortable: true },
        { key: "YetkiliKisi", label: "Yetkili", sortable: true },
        { key: "SehirAdi", label: "Şehir", sortable: true },
        { key: "GSM", label: "GSM", sortable: true, thClass: "sabitKol" },
        { key: "Email", label: "Email", sortable: true, thClass: "sabitKol" },
        { key: "ToplamBorc", label: "Bakiye", sortable: true },
        { key: "DurumStr", label: "Durum", sortable: true },
        { key: "SonRezervasyonTarihi", label: "Son Rez.", sortable: true },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: true },
      ],

      items: [],

      toplamBorc: 0,

      date_format: "dd.MM.yyyy",
      tr: tr,

      filtre_durumlar: [
        { value: null, text: "Durumlar" },
        { value: "0", text: "Yeni" },
        { value: "20", text: "Pasif" },
        { value: "30", text: "Onaylı" },
        { value: "100", text: "İptal" },
      ],

      filtre_tipler: [
        { value: null, text: "Tipler" },
        { value: "0", text: "Acente" },
        { value: "10", text: "Otel" }
      ],

      filtre_sehirler: [{ value: null, text: "Şehirler" }],

      filtre: {
        Kelime: null,
        BaslangicTarihi: null,
        BitisTarihi: null,
        SehirId: null,
        MinBorc: null,
        MaxBorc: null,
        Durum: this.$route.query.durum != null ? this.$route.query.durum : null,
        Tipi: this.$route.query.tipi != null ? this.$route.query.tipi : null,
      },
    };
  },
  components: {
    Datepicker,
  },
  mounted() {
    this.onSehirList();
    this.onList();
  },
  methods: {
    onFiltreSubmit(evt) {
      evt.preventDefault();
      this.onList();
    },
    onSehirList() {
      let vm = this;

      this.$connection.get("/sehirler/select", function (response) {
        vm.filtre_sehirler = vm.filtre_sehirler.concat(response);
      });
    },
    onList() {
      let vm = this;

      vm.filtre.MinBorc = vm.filtre.MinBorc === "" ? null : vm.filtre.MinBorc;
      vm.filtre.MaxBorc = vm.filtre.MaxBorc === "" ? null : vm.filtre.MaxBorc;

      this.$connection.post(
        "/acenteler/filtre",
        vm.filtre,
        "post",
        function (response) {
          vm.items = response;

          vm.toplamBorc = 0;
          vm.items.forEach((item) => {
            vm.toplamBorc += item.ToplamBorc;
          });
        }
      );
    },
  },
};
</script>
