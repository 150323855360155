<template>
  <div>
    <Breadcrumbs main="Rehberler" title="Liste" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card" v-if="isShowSirala == false">
            <div class="card-body avatar-showcase">
              <div class="avatars dilRehberGrup">
                <div class="avatar" v-for="dil in dilListe" :key="dil.Id">
                  <a href="#" @click="dilFiltreSec(dil)" :class="dil.Id == filtre.DilId ? 'active' : ''">
                    <img class="img-50 rounded-circle" :src="dil.Bayrak" :alt="dil.DilAdi" />
                    <b-badge variant="info">{{ dil.RehberSayisi }}</b-badge>
                  </a>
                </div>
                <div class="btn-group pull-right">
                  <router-link to="/rehber/yogunluk" class="btn btn-info">Yoğunluk Takvimi</router-link>
                  <b-button @click="isShowSirala = true" class="btn btn-default">Sırala</b-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <h5>
                Toplam Borç :
                <b-badge variant="info"><c-money-cell :fiyat="toplamBorc" simge="₺"></c-money-cell></b-badge>
              </h5>
              <hr />
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Islem)="row">
                  <b-btn-group>
                    <a :href="`/rehber/detay/${row.item.Id}`" class="btn btn-info btn-xs" target="_blank">Detay</a>
                    <router-link :to="`/rehber/kayit/${row.item.Id}`"
                      class="btn btn-success btn-xs">Düzelt</router-link>
                    <b-button size="xs" @click="sil(row.item)" variant="danger">Sil</b-button>
                  </b-btn-group>
                </template>
                <template #cell(Resim)="data">
                    <img class="img-50 rounded-circle" :src="data.value" alt="" />
                </template>
                <template #cell(Diller)="data">
                  <ul>
                    <li v-for="(dil, index) in data.value" :key="index" class="d-inline-block">
                      <img class="img-20 rounded-circle" :src="dil" alt="" />
                    </li>
                  </ul>
                </template>
                <template #cell(ToplamAlacak)="data">
                  <c-money-cell :fiyat="data.value" simge="₺"></c-money-cell>
                </template>
                <template #cell(GSM)="data">
                  <a :href="'tel:0090' + data.value">{{ data.value }}</a>
                </template>
                <template #cell(Email)="data">
                  <a :href="'mailto:' + data.value">{{ data.value }}</a>
                </template>
                <template #cell(Yildiz)="row">
                  <c-performans-cell :Yildiz="row.item.Yildiz" :MemnunOlmayanlar="row.item.PerformansMemnunOlmayanlar"
                    :Notr="row.item.PerformansNotr" :SavunmaRedSayisi="row.item.PerformansSavunmaRedSayisi"
                    :rowKey="row.item.Id" :key="row.item.Id"></c-performans-cell>
                </template>
                <template #cell(DurumStr)="row">
                  <b-badge :variant="durumVariant(row.item.Durum)">{{ row.item.DurumStr }}</b-badge>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell :kayitTarihi="row.item.KayitTarihi" :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>

          <div class="card" v-if="isShowSirala">
            <div class="card-header">
              Rehber Sırala
              <div class="btn-group pull-right">
                <b-button @click="isShowSirala = false" class="btn btn-dark">Sıralama Kapat</b-button>
                <b-button @click="siralamaKaydet()" class="btn btn-success">Sıralamayı Kaydet</b-button>
              </div>
            </div>
            <div class="card-body">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Adı</th>
                    <th scope="col">Dil</th>
                    <th scope="col">Bakiye</th>
                    <th scope="col">Sıra</th>
                    <th scope="col">Durum</th>
                    <th scope="col">Kayıt</th>
                  </tr>
                </thead>
                <draggable v-model="items" tag="tbody" @change="draggableChange">
                  <tr v-for="item in items" :key="item.Id">
                    <td>
                      <i class="fa fa-align-justify"></i>
                      {{ item.AdSoyad }}
                    </td>
                    <td>
                      <ul>
                        <li v-for="(dil, index) in item.Diller" :key="index" class="d-inline-block">
                          <img class="img-20 rounded-circle" :src="dil" alt="" />
                        </li>
                      </ul>
                    </td>
                    <td>
                      <c-money-cell :fiyat="item.ToplamAlacak" simge="₺"></c-money-cell>
                    </td>
                    <td>
                      <a href="javascript:" @click="siraDegistir(item, 2)" class="btn btn-light btn-xs"><i
                          class="fa fa-angle-double-up"></i></a>
                      <a href="javascript:" @click="siraDegistir(item, 1)" class="btn btn-xs"><i
                          class="fa fa-angle-up"></i></a>
                      <a href="javascript:" @click="siraDegistir(item, -1)" class="btn btn-xs"><i
                          class="fa fa-angle-down"></i></a>
                      <a href="javascript:" @click="siraDegistir(item, -2)" class="btn btn-dark btn-xs"><i
                          class="fa fa-angle-double-down"></i></a>
                      <b-badge variant="info" class="ml-1">{{ item.Sira }}</b-badge>
                    </td>
                    <td><b-badge :variant="durumVariant(item.Durum)">{{ item.DurumStr }}</b-badge></td>
                    <td>
                      <c-tarih-cell :kayitTarihi="item.KayitTarihi" :guncelTarih="item.GuncelTarih"
                        :rowKey="item.Id"></c-tarih-cell>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";

export default {
  data() {
    return {
      breadLinks: [{ path: "/rehber/kayit", title: "Ekle", icon: "plus" }],
      tablefields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "Resim", label: "Resim", sortable: true },
        { key: "AdSoyad", label: "Adı", sortable: true },
        { key: "Diller", label: "Dil", sortable: true },
        { key: "GSM", label: "GSM", sortable: true, thClass: "sabitKol", tdClass: "sabitKol", },
        { key: "Email", label: "Email", sortable: true, thClass: "sabitKol", tdClass: "sabitKol", },
        { key: "ToplamAlacak", label: "Bakiye", sortable: true },
        { key: "Yildiz", label: "Performans", sortable: true },
        { key: "Sira", label: "Sıra", sortable: true },
        { key: "DurumStr", label: "Durum", sortable: true },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: true },
      ],

      dilListe: [],
      filtre: {
        DilId: null,
      },
      items: [],

      toplamBorc: 0,

      isShowSirala: false
    };
  },
  components: {
    draggable
  },
  mounted() {
    this.dilGrupList();
    this.onList();
  },
  methods: {
    dilFiltreSec(dil) {
      if (dil.Id === this.filtre.DilId) {
        this.filtre.DilId = null;
      } else {
        this.filtre.DilId = dil.Id;
      }
      this.onList();
    },
    dilGrupList() {
      let vm = this;

      this.$connection.get("/rehberler/dilGrup", function (response) {
        vm.dilListe = response;
      });
    },
    onList() {
      let vm = this;

      this.$connection.post(
        "/rehberler/filtre",
        this.filtre,
        "post",
        function (response) {
          vm.items = response;

          vm.toplamBorc = 0;
          vm.items.forEach((item) => {
            vm.toplamBorc += item.ToplamAlacak;
          });
        }
      );
    },
    sil(item) {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Rehber kaydını silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete("/rehberler/" + item.Id, function (response) {
            vm.$alert.messageWithFunction(
              "Silindi",
              "Rehber kaydı silindi.",
              "error",
              function () {
                vm.$listHelper.removeItem(vm.items, item);
              }
            );
          });
        }
      );
    },
    durumVariant(durum) {
      switch (durum) {
        case 0:
          return "info";
        case 20:
          return "warning";
        case 30:
          return "success";
        case 40:
          return "dark";
        case 100:
          return "danger";
        default:
          return "light";
      }
    },
    draggableChange(log) {
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        element.Sira = index;
      }
    },
    siralamaKaydet() {
      let vm = this;

      let ids = vm.items.map((item) => {
        return item.Id;
      });
      let form = {
        Ids: ids,
      };

      this.$connection.post(
        "/rehberler/sirala",
        form,
        "put",
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithTitle(
              "Sıralama Kaydet",
              response.Message,
              "success"
            );
          } else {
            vm.$alert.messageWithTitle(
              "Sıralama Kaydet",
              response.Message,
              "error"
            );
          }
        }
      );
    },
    siraDegistir(kayit, index) {
      let sira = kayit.Sira;

      switch (index) {
        case 2:
          kayit.Sira = -1;
          break;

        case 1:
          sira = sira - 2;
          kayit.Sira = sira;
          break;

        case -1:
          sira = sira + 2;
          kayit.Sira = sira;
          break;

        case -2:
          kayit.Sira = this.items.length;
          break;
      }

      this.items.sort((a, b) => a.Sira - b.Sira);

      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        element.Sira = index;
      }
    }
  },
};
</script>
